import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

Fancybox.defaults.l10n = {
  CLOSE: 'Schlie&szlig;en',
  NEXT: 'Weiter',
  PREV: 'Zurück',
  ERROR:
    'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
  TOGGLE_SLIDESHOW: 'Diaschau ',
  TOGGLE_FULLSCREEN: 'Vollbild',
  TOGGLE_THUMBS: 'Vorschaubilder',
  ITERATEZOOM: 'Zoom',
  DOWNLOAD: 'Herunterladen',
  SHARE: 'Teilen',
  ZOOM: 'Vergr&ouml;&szlig;ern',
}

let room = undefined

Fancybox.bind('[data-meeting-button]', {
  Toolbar: {
    enabled: true,
    display: {
      right: ['close'],
    },
  },
  backdropClick: false,
  Html: {
    closeBtn: false,
  },
  Carousel: {
    Panzoom: false,
  },
  on: {
    reveal: (fancybox) => {
      const scriptTag = fancybox.container.querySelector('script[data-src]')

      // Überprüfen, ob ein solches Script existiert
      if (scriptTag) {
        // Neues <script>-Tag erstellen
        const newScript = document.createElement('script')

        // Das `data-src`-Attribut in ein `src`-Attribut umwandeln
        newScript.src = scriptTag.getAttribute('data-src')

        // Andere Attribute kopieren (falls nötig, wie 'type', 'async', etc.)
        if (scriptTag.type) newScript.type = scriptTag.type
        if (scriptTag.async) newScript.async = true
        if (scriptTag.defer) newScript.defer = true

        // Das neue Script in den DOM einfügen
        document.body.appendChild(newScript)

        room = fancybox.container.querySelector('whereby-embed')
        room.addEventListener('leave', function () {
          // fancybox.close()
          window.location.reload()
        })

        console.log('Script wurde erfolgreich aktiviert und eingefügt.')
      } else {
        console.error('Kein Script-Tag mit "data-src" gefunden.')
      }
    },
    close: () => {
      window.location.reload()
    },
  },
})
