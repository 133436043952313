<script setup>
import { reactive, watch, toRefs, ref, onMounted } from 'vue'

const emit = defineEmits(['update:modelValue'])

const checkboxGroupRef = ref(null)

const selected = reactive({
  items: [],
})

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  labelInfo: {
    type: String,
    default: '',
    required: false,
  },
  required: {
    type: Boolean,
    default: true,
    required: false,
  },
  options: {
    type: Array,
    default: () => [],
    required: true,
  },
  modelValue: {
    type: [String, Boolean],
    default: '',
    required: false,
  },
  customErrorMessage: {
    type: String,
    default: '',
    required: false,
  },
  isValidationActive: {
    type: Boolean,
    default: false,
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  formName: {
    type: String,
    default: '',
  },
})

const reactiveProps = toRefs(props)

const setCheckbox = (id) => {
  if (selected.items.includes(id)) {
    selected.items = selected.items.filter(function (item) {
      return item !== id
    })
  } else {
    selected.items = selected.items.filter(function (item) {
      return item !== 'all'
    })
    selected.items.push(id)
  }

  emit('update:modelValue', selected.items.join('|'))
  checkValidity()
}

function checkValidity() {
  let error = ''
  if (
    props.required &&
    props.isValidationActive &&
    selected.items.length == 0
  ) {
    error = props.customErrorMessage
  }
  checkboxGroupRef.value.forEach((input, index) => {
    if (index == 0) {
      input.setCustomValidity(error)
      input.reportValidity()
    }
  })
}

const updateSelectedValues = (newValue) => {
  selected.items = newValue.split('|')
}

onMounted(() => {
  if (props.modelValue) {
    updateSelectedValues(props.modelValue)
  }
  checkValidity()
})

watch(
  () => props.isValidationActive,
  () => {
    checkValidity()
  },
)

watch(
  () => reactiveProps.modelValue,
  (newValue) => {
    checkValidity()
    updateSelectedValues(newValue)
  },
)
</script>

<template>
  <fieldset class="CheckboxGroup">
    <legend>
      {{ props.label }}
      <template v-if="!props.required"><span>(optional)</span></template>
    </legend>

    <div
      v-for="option in props.options"
      :key="option.id"
      class="CheckboxGroup-item"
    >
      <input
        :id="props.name + '-' + option.id"
        ref="checkboxGroupRef"
        type="checkbox"
        class="visually-hidden"
        :name="props.name + '[]'"
        :value="option.id"
        :disabled="isDisabled"
        :checked="selected.items.includes(option.id)"
        @input="setCheckbox(option.id)"
      />
      <label :for="props.name + '-' + option.id">{{ option.label }}</label>
    </div>
  </fieldset>
</template>
