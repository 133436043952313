<script setup>
import { watch, ref } from 'vue'

import CheckboxGroup from '../CheckboxGroup/CheckboxGroup.vue'
import RadioGroup from '../RadioGroup/RadioGroup.vue'

defineEmits(['update:modelValue'])
const currentFieldRef = ref(null)
const showPassword = ref(false)

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  labelInfo: {
    type: String,
    default: '',
    required: false,
  },
  fieldType: {
    type: String,
    default: 'text',
    required: false,
  },
  customErrorMessage: {
    type: String,
    default: '',
    required: false,
  },
  required: {
    type: Boolean,
    default: true,
    required: false,
  },
  hideField: {
    type: Boolean,
    default: false,
  },
  options: {
    type: Array,
    default: () => [],
    required: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
    required: false,
  },
  isCustomInvalid: {
    type: Boolean,
    default: false,
  },
  isValidationActive: {
    type: Boolean,
    default: false,
    required: false,
  },
  modelValue: {
    type: [String, Boolean, undefined],
    default: undefined,
    required: false,
  },
  formName: {
    type: String,
    default: '',
  },
})

function toggleShowPassword() {
  showPassword.value = !showPassword.value
}

watch(
  () => props.isCustomInvalid,
  (newValue) => {
    if (newValue == true) {
      currentFieldRef.value.setCustomValidity(props.customErrorMessage)
      currentFieldRef.value.reportValidity()
    } else {
      currentFieldRef.value.setCustomValidity('')
      currentFieldRef.value.reportValidity()
    }
  },
)
</script>

<template>
  <div
    v-if="!hideField"
    class="InputField field"
    :data-error="props.customErrorMessage"
    :class="{
      [`field_${props.fieldType}`]: true,
      [`field--customInvalid`]: props.isCustomInvalid,
      [`field--disabled`]: props.isDisabled,
    }"
  >
    <label
      v-if="
        props.fieldType !== 'checkbox' &&
        props.fieldType !== 'checkboxGroup' &&
        props.fieldType !== 'radioGroup'
      "
      :for="props.formName + props.name"
      class="InputField-fieldLabel"
    >
      {{ props.label }}
      <template v-if="!props.required"><span>(optional)</span></template>
      <span v-if="props.labelInfo" class="InputField-fieldLabelInfo">
        ({{ props.labelInfo }})</span
      >
    </label>
    <template v-if="props.fieldType == 'select'">
      <select
        :id="props.formName + props.name"
        ref="currentFieldRef"
        :value="modelValue"
        :name="props.name"
        :disabled="isDisabled"
        :required="props.required"
        @input="$emit('update:modelValue', $event.target.value)"
      >
        <option disabled value="">Bitte auswählen</option>
        <option
          v-for="option in props.options"
          :key="option.id"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
    </template>
    <template v-else-if="props.fieldType == 'radioGroup'">
      <RadioGroup
        v-bind="props"
        :is-disabled="isDisabled"
        @update:model-value="$emit('update:modelValue', $event)"
      ></RadioGroup>
    </template>
    <template v-else-if="props.fieldType == 'checkboxGroup'">
      <CheckboxGroup
        v-bind="props"
        :is-disabled="isDisabled"
        @update:model-value="$emit('update:modelValue', $event)"
      ></CheckboxGroup>
    </template>
    <template v-else-if="props.fieldType == 'checkbox'">
      <input
        :id="props.formName + props.name"
        ref="currentFieldRef"
        :name="props.name"
        :type="props.fieldType"
        :value="true"
        :checked="modelValue"
        :required="props.required"
        :disabled="isDisabled"
        @input="$emit('update:modelValue', $event.target.checked)"
      />
      <!-- eslint-disable vue/no-v-html -->
      <label :for="props.formName + props.name" v-html="props.label"> </label>
      <!--eslint-enable-->
    </template>
    <template v-else-if="props.fieldType == 'password'">
      <div class="InputField--withActions">
        <input
          :id="props.formName + props.name"
          ref="currentFieldRef"
          :value="modelValue"
          :disabled="isDisabled"
          :class="{
            'InputField-fieldInput': true,
            'InputField-fieldInput--default': true,
          }"
          :type="!showPassword ? props.fieldType : 'text'"
          :required="props.required"
          :name="props.name"
          :minlength="props.fieldType == 'password' ? 8 : ''"
          :autocomplete="props.fieldType == 'password' ? 'new-password' : ''"
          :placeholder="props.placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
        />
        <button
          v-if="props.fieldType == 'password'"
          class="InputField-action"
          @click.prevent="toggleShowPassword()"
        >
          <i v-if="!showPassword" class="las la-eye"></i>
          <i v-if="showPassword" class="las la-eye-slash"></i>
        </button>
      </div>
    </template>
    <template v-else>
      <component
        :is="props.fieldType == 'textarea' ? 'textarea' : 'input'"
        :id="props.formName + props.name"
        ref="currentFieldRef"
        :value="modelValue"
        :disabled="isDisabled"
        :class="{
          'InputField-fieldInput': true,
          'InputField-fieldInput--textarea': props.fieldType == 'textarea',
          'InputField-fieldInput--default': props.fieldType != 'textarea',
        }"
        :type="props.fieldType"
        :required="props.required"
        :name="props.name"
        :minlength="props.fieldType == 'password' ? 8 : ''"
        :autocomplete="props.fieldType == 'password' ? 'new-password' : ''"
        :placeholder="props.placeholder"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </template>
  </div>
</template>
