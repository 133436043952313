const menuItems = document.querySelectorAll('.MenuItem--children')

Array.prototype.forEach.call(menuItems, function (item) {
  const showButton = item.querySelector(':scope > .MenuItem-button--show')
  const hideButton = item.querySelector(':scope > .MenuItem-button--hide')

  if (showButton && hideButton) {
    showButton.addEventListener('click', () => {
      item.setAttribute('aria-expanded', 'true')
      showButton.classList.remove('MenuItem-button--active')
      hideButton.classList.add('MenuItem-button--active')
    })

    hideButton.addEventListener('click', () => {
      item.setAttribute('aria-expanded', 'false')
      hideButton.classList.remove('MenuItem-button--active')
      showButton.classList.add('MenuItem-button--active')
    })
  }
})
