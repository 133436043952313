<template>
  <form
    ref="registerReferentForm"
    class="AccountRegistration-form --default"
    :class="{ '--validate': validate, '--pending': pending }"
    method="post"
    @submit.prevent="submitRegistration()"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div
      v-if="infoText != '' && !success && !error"
      class="info-text"
      v-html="infoText"
    ></div>
    <!--eslint-enable-->

    <div v-if="error" class="Message Message--error">{{ error }}</div>
    <div v-if="success" class="Message Message--success">{{ success }}</div>

    <input type="text" name="username" placeholder="Username" />
    <template v-if="!success">
      <input-field
        v-for="field in fields"
        :key="field.name"
        v-model="field.modelValue"
        :hide-field="hideField(field)"
        form-name="accountRegistration"
        v-bind="field"
        :is-disabled="pending"
        :is-validation-active="validate"
        :is-custom-invalid="isCustomInvalid(field)"
        :class="{
          'AccountRegistration-field': true,
          'AccountRegistration-field--textarea': field.type == 'textarea',
          'AccountRegistration-field--default': field.type != 'textarea',
        }"
      />
    </template>

    <button
      v-if="!success"
      class="blue-button"
      :disabled="pending"
      type="submit"
      @click="validate = true"
    >
      Registrieren
    </button>
  </form>
</template>

<script>
import InputField from '../InputField/InputField.vue'
import axios from 'axios'

export default {
  components: {
    InputField,
  },
  data() {
    return {
      fields: document.jsVariables.registrationFields,
      error: false,
      success: false,
      pending: false,
      view: 'login',
      validate: false,
      infoText: document.jsVariables.registrationInfo,
    }
  },
  methods: {
    submitRegistration() {
      this.error = false
      this.success = false
      this.validate = true

      if (this.checkForCustomErrors() == false) {
        this.sendForm()
      }
    },
    hideField(field) {
      if (field.showOnlyIf == undefined) return false
      const name = field.showOnlyIf.name
      const values = field.showOnlyIf.value
      const currentValue = this.fields.find((el) => el.name == name).modelValue

      if (values.includes(currentValue)) return false

      return true
    },
    checkForCustomErrors() {
      let errors = false
      this.fields.forEach((field) => {
        if (this.isCustomInvalid(field)) errors = true
      })

      return errors
    },
    isCustomInvalid(field) {
      if (
        this.validate &&
        field.name == 'passwordConfirm' &&
        !this.isPasswordConfirmValid()
      )
        return true

      return false
    },
    isPasswordConfirmValid() {
      const password = this.fields.find(
        (el) => el.name == 'password',
      ).modelValue
      const passwordConfirm = this.fields.find(
        (el) => el.name == 'passwordConfirm',
      ).modelValue

      if (passwordConfirm == undefined && password == undefined) return true
      if (passwordConfirm !== '' && passwordConfirm !== password) return false
      return true
    },
    sendForm() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue
      const formData = new FormData(this.$refs.registerReferentForm)

      this.validate = false
      this.pending = true

      axios
        .post(document.jsVariables.apiBaseUrl + 'registration', formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.success =
              'Vielen Dank für Ihre Registrierung. Sie erhalten in Kürze eine E-Mail um Ihren Account zu aktivieren.'
          }
        })
        .catch((error) => {
          this.pending = false
          if (error.response.status == 500) {
            this.error =
              'Leider ging bei der Verarbeitung des Formulares etwas schief. Versuchen Sie es bitte in Kürze erneut. Sollte es weiterhin nicht funktionieren, geben Sie uns gerne Bescheid.'
          } else {
            this.error = error.response.data
          }
        })
    },
  },
}
</script>
