import Plyr from 'plyr/dist/plyr.min.js'
// import * as Plyr from 'plyr'
import '@/node_modules/plyr/dist/plyr.css'
import videoTranslations from '@/js/misc/plyr-translation.js'

const TitleVideoElements = document.querySelectorAll('.TitleImage--video video')

Array.prototype.forEach.call(TitleVideoElements, function (videoElement) {
  new Plyr(videoElement, {
    controls: [
      'progress',
      'play-large',
      'play',
      'mute',
      'volume',
      'current-time',
      'duration',
      'fullscreen',
    ],
    i18n: videoTranslations,
    resetOnEnd: true,
    language: 'de',
  })
})
