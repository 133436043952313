export default {
  restart: 'Neustart',
  rewind: '{seektime} Sekunden zurückspulen',
  play: 'Abspielen',
  pause: 'Pause',
  fastForward: '{seektime} Sekunden vorspulen',
  seek: 'Suche',
  seekLabel: '{currentTime} von {duration}',
  played: 'Abgespielt',
  buffered: 'Gepuffert',
  currentTime: 'Aktuelle Zeit',
  duration: 'Dauer',
  volume: 'Lautstärke',
  mute: 'Stummschalten',
  unmute: 'Stummschaltung aufheben',
  enableCaptions: 'Untertitel aktivieren',
  disableCaptions: 'Untertitel deaktivieren',
  download: 'Download',
  enterFullscreen: 'Vollbild öffnen',
  exitFullscreen: 'Vollbild schließen',
  frameTitle: 'Videoplay für {title}',
  captions: 'Untertitel',
  settings: 'Einstellungen',
  pip: 'Bild in Bild',
  menuBack: 'Zurück zum vorherigen Menü',
  speed: 'Geschwindigkeit',
  normal: 'Normal',
  quality: 'Qualität',
  loop: 'Endlosschleife',
  start: 'Start',
  end: 'Ende',
  all: 'Alle',
  reset: 'Zurücksetzen',
  disabled: 'Deaktiviert',
  enabled: 'Aktiviert',
  advertisement: 'Werbung',
  qualityBadge: {
    2160: '4K',
    1440: 'HD',
    1080: 'HD',
    720: 'HD',
    576: 'SD',
    480: 'SD',
  },
}
