<template>
  <button
    class="WatchList-itemRemoveButton"
    :class="{
      'WatchList-itemRemoveButton--deleted': isDeleted,
      'WatchList-itemRemoveButton--pending': isPending,
    }"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
    @click="removeItemFromWatchList()"
  >
    <svg
      width="23"
      height="30"
      viewBox="0 0 23 30"
      fill="none"
      aria-hidden="true"
      class="WatchList-itemRemoveIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="!isHovered && !isPending"
        d="M0.62207 0.509766H22.9998V29.1035L11.8109 20.7119L0.62207 29.1035V0.509766Z"
      />

      <path
        v-if="isHovered || isPending"
        d="M0.170898 0.509766H22.5486V29.1035L11.3598 20.7119L0.170898 29.1035V0.509766ZM2.65731 2.99618V24.1307L11.3598 17.6039L20.0622 24.1307V2.99618H2.65731Z"
      />
    </svg>
  </button>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'

const isHovered = ref(false)
const isPending = ref(false)
const isDeleted = ref(false)

const props = defineProps({
  id: {
    type: String,
    default: '',
    required: true,
  },
})

const removeItemFromWatchList = function () {
  if (isPending.value) return
  isPending.value = true

  const tokenName = 'X-' + document.csrfTokenName
  const tokenValue = document.csrfTokenValue

  axios
    .post(
      document.jsVariables.apiBaseUrl + 'removeWatchListItem',
      { id: props.id },
      {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          [tokenName]: tokenValue,
        },
      },
    )
    .then((response) => {
      isPending.value = false
      if (response.status == 200) {
        isDeleted.value = true
      }
    })
}
</script>
