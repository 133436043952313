<script setup>
import { reactive, watch, toRefs, onMounted } from 'vue'

const emit = defineEmits(['update:modelValue'])

const selected = reactive({
  value: undefined,
})

const props = defineProps({
  name: {
    type: String,
    default: '',
  },
  label: {
    type: String,
    default: '',
  },
  labelInfo: {
    type: String,
    default: '',
    required: false,
  },
  required: {
    type: Boolean,
    default: true,
    required: false,
  },
  options: {
    type: Array,
    default: () => [],
    required: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [String, Boolean],
    default: '',
    required: false,
  },
  formName: {
    type: String,
    default: '',
  },
})

const reactiveProps = toRefs(props)

const setCheckbox = (id) => {
  selected.value = id
  emit('update:modelValue', selected.value)
}

const updateSelectedValues = (newValue) => {
  selected.value = newValue
}

onMounted(() => {
  updateSelectedValues(props.modelValue)
})

watch(
  () => reactiveProps.modelValue,
  (newValue) => {
    updateSelectedValues(newValue)
  },
)
</script>

<template>
  <fieldset class="RadioGroup">
    <legend>
      {{ props.label }}
      <template v-if="!props.required"><span>(optional)</span></template>
    </legend>

    <div
      v-for="option in props.options"
      :key="option.id"
      class="RadioGroup-item"
    >
      <input
        :id="props.name + '-' + option.id"
        type="radio"
        :disabled="isDisabled || option.disabled"
        :name="props.name"
        :value="option.id"
        :checked="selected.value == option.id"
        @input="setCheckbox(option.id)"
      />

      <label :for="props.name + '-' + option.id">{{ option.label }}</label>
    </div>
  </fieldset>
</template>
