import Plyr from 'plyr/dist/plyr.min.js'
// import * as Plyr from 'plyr'
import '@/node_modules/plyr/dist/plyr.css'

import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

Fancybox.defaults.l10n = {
  CLOSE: 'Schlie&szlig;en',
  NEXT: 'Weiter',
  PREV: 'Zurück',
  ERROR:
    'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
  TOGGLE_SLIDESHOW: 'Diaschau ',
  TOGGLE_FULLSCREEN: 'Vollbild',
  TOGGLE_THUMBS: 'Vorschaubilder',
  ITERATEZOOM: 'Zoom',
  DOWNLOAD: 'Herunterladen',
  SHARE: 'Teilen',
  ZOOM: 'Vergr&ouml;&szlig;ern',
}

Fancybox.bind('[data-composite-media-object-modal]', {
  hideClass: false,
  Toolbar: {
    enabled: true,
    display: {
      right: ['close'],
    },
  },
  Html: {
    closeBtn: true,
    autoSize: false,
  },
  Carousel: {
    Panzoom: false,
  },
  defaultType: 'inline',
})

const CompositeMediaObjectElement = document.querySelectorAll(
  '.CompositeMediaObject',
)

Array.prototype.forEach.call(
  CompositeMediaObjectElement,
  function (compositeMediaObject) {
    let currentStep = undefined
    const mediaId = compositeMediaObject.getAttribute('data-id')
    const steps = document.jsVariables.compositeMedia[mediaId].steps

    const detailsElementList = compositeMediaObject.querySelector(
      '.CompositeMediaObject-detailsList',
    )

    const toggleElement = compositeMediaObject.querySelector(
      '.CompositeMediaObject-toggle .Toggle-input',
    )

    const videoElement = compositeMediaObject.querySelector(
      '.CompositeMediaObject-video',
    )

    const videoPlayer = new Plyr(
      '.CompositeMediaObject--' + mediaId + ' .CompositeMediaObject-video',
      {
        controls: [
          'progress',
          'play',
          'mute',
          'volume',
          'current-time',
          'duration',

          'captions',
          'settings',
          videoElement.getAttribute('data-download') ? 'download' : '',
          'fullscreen',
        ],
        i18n: {
          restart: 'Neustart',
          rewind: '{seektime} Sekunden zurückspulen',
          play: 'Abspielen',
          pause: 'Pause',
          fastForward: '{seektime} Sekunden vorspulen',
          seek: 'Suche',
          seekLabel: '{currentTime} von {duration}',
          played: 'Abgespielt',
          buffered: 'Gepuffert',
          currentTime: 'Aktuelle Zeit',
          duration: 'Dauer',
          volume: 'Lautstärke',
          mute: 'Stummschalten',
          unmute: 'Stummschaltung aufheben',
          enableCaptions: 'Untertitel aktivieren',
          disableCaptions: 'Untertitel deaktivieren',
          download: 'Download',
          enterFullscreen: 'Vollbild öffnen',
          exitFullscreen: 'Vollbild schließen',
          frameTitle: 'Videoplay für {title}',
          captions: 'Untertitel',
          settings: 'Einstellungen',
          pip: 'Bild in Bild',
          menuBack: 'Zurück zum vorherigen Menü',
          speed: 'Geschwindigkeit',
          normal: 'Normal',
          quality: 'Qualität',
          loop: 'Endlosschleife',
          start: 'Start',
          end: 'Ende',
          all: 'Alle',
          reset: 'Zurücksetzen',
          disabled: 'Deaktiviert',
          enabled: 'Aktiviert',
          advertisement: 'Werbung',
          qualityBadge: {
            2160: '4K',
            1440: 'HD',
            1080: 'HD',
            720: 'HD',
            576: 'SD',
            480: 'SD',
          },
        },
        resetOnEnd: true,
        language: 'de',
        markers: {
          enabled: true,
          points: document.jsVariables.compositeMedia[mediaId].markers,
        },
      },
    )

    function setCurrentStep(newStep) {
      currentStep = newStep

      Array.prototype.forEach.call(
        compositeMediaObject.querySelectorAll('.CompositeMediaObject-detail'),
        function (thumbnail) {
          thumbnail.setAttribute('aria-current', 'false')
        },
      )

      if (newStep != undefined) {
        compositeMediaObject.classList.add(
          'CompositeMediaObject--hasCurrentStep',
        )

        const currentDetail = compositeMediaObject.querySelector(
          '.CompositeMediaObject-detail[data-id="' + newStep.id + '"]',
        )

        currentDetail.setAttribute('aria-current', 'true')

        const offsetCompositeDetails = detailsElementList.offsetHeight / 4

        detailsElementList.scrollTo({
          top: currentDetail.offsetTop - offsetCompositeDetails,
          left: 0,
          behavior: 'smooth',
        })
      } else {
        compositeMediaObject.classList.remove(
          'CompositeMediaObject--hasCurrentStep',
        )
      }
    }

    window.addEventListener('keydown', function (event) {
      if (!compositeMediaObject.classList.contains('fancybox__content')) return

      let currentStepKey = undefined

      if (currentStep != undefined) {
        currentStepKey = Object.keys(steps).find(
          (x) => steps[x].id == currentStep.id,
        )
      }

      if (event.keyCode == '33') {
        event.preventDefault()
        if (currentStep == undefined) return

        if (currentStepKey > 0) {
          const previousStep = steps[parseInt(currentStepKey) - 1]
          videoPlayer.currentTime = previousStep.timecode
          return
        }
      }

      if (event.keyCode == '34') {
        event.preventDefault()
        if (currentStep == undefined) {
          videoPlayer.currentTime = steps[0].timecode
          return
        }

        if (currentStepKey < steps.length - 1) {
          const nextStep = steps[parseInt(currentStepKey) + 1]
          videoPlayer.currentTime = nextStep.timecode
          return
        }
      }
    })

    videoPlayer.on('timeupdate', () => {
      let newStep = undefined
      const currentTime = parseInt(videoPlayer.currentTime)

      const foundStep = steps.find(
        (step, index) =>
          currentTime >= parseInt(step.timecode) &&
          (steps[index + 1] == undefined ||
            parseInt(steps[index + 1].timecode) > currentTime),
      )

      if (foundStep == undefined && currentTime < parseInt(steps[0].timecode)) {
        newStep = undefined
      }

      if (foundStep != undefined) {
        newStep = foundStep
      }

      if (currentStep != newStep) {
        setCurrentStep(newStep)
      }
    })

    const detailButtons = compositeMediaObject.querySelectorAll(
      '.CompositeMediaObject-detailButton',
    )

    Array.prototype.forEach.call(detailButtons, function (detailButton) {
      detailButton.addEventListener('click', function (event) {
        event.stopPropagation()
        const timecode = parseFloat(detailButton.getAttribute('data-timecode'))
        videoPlayer.currentTime = timecode
      })
    })

    const details = compositeMediaObject.querySelectorAll(
      '.CompositeMediaObject-detail',
    )

    Array.prototype.forEach.call(details, function (detail) {
      detail.addEventListener('click', function () {
        const timecode = parseFloat(detail.getAttribute('data-timecode'))

        videoPlayer.currentTime = timecode
      })
    })

    const moreButtons = compositeMediaObject.querySelectorAll(
      '.CompositeMediaObject-detailMore',
    )

    Array.prototype.forEach.call(moreButtons, function (moreButton) {
      moreButton.addEventListener('click', function (event) {
        event.stopPropagation()
        moreButton.parentElement.parentElement.parentElement.parentElement.setAttribute(
          'aria-expanded',
          true,
        )
      })
    })

    const lessButtons = compositeMediaObject.querySelectorAll(
      '.CompositeMediaObject-detailLess',
    )

    Array.prototype.forEach.call(lessButtons, function (lessButton) {
      lessButton.addEventListener('click', function (event) {
        event.stopPropagation()
        lessButton.parentElement.parentElement.parentElement.parentElement.setAttribute(
          'aria-expanded',
          false,
        )
      })
    })

    toggleElement.addEventListener('click', function () {
      let setDetailAttributeTo = 'false'

      if (toggleElement.checked) {
        setDetailAttributeTo = 'true'
      }

      Array.prototype.forEach.call(
        compositeMediaObject.querySelectorAll('.CompositeMediaObject-detail'),
        function (thumbnail) {
          thumbnail.setAttribute('aria-expanded', setDetailAttributeTo)
        },
      )
    })
  },
)
