import $ from 'jquery'
import '../../js/old'
import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

$('.load3DModelButton').on('click', function () {
  // eslint-disable-next-line
  const parent = this.closest('section')
  const modelContainer = parent.querySelector('div.model-container')
  modelContainer.classList.remove('hidden')
  parent.querySelector('model-viewer').dismissPoster()
})

Fancybox.defaults.l10n = {
  CLOSE: 'Schlie&szlig;en',
  NEXT: 'Weiter',
  PREV: 'Zurück',
  ERROR:
    'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
  TOGGLE_SLIDESHOW: 'Diaschau ',
  TOGGLE_FULLSCREEN: 'Vollbild',
  TOGGLE_THUMBS: 'Vorschaubilder',
  ITERATEZOOM: 'Zoom',
  DOWNLOAD: 'Herunterladen',
  SHARE: 'Teilen',
  ZOOM: 'Vergr&ouml;&szlig;ern',
}

Fancybox.bind('[data-model-modal]', {
  Toolbar: {
    enabled: true,
    display: {
      right: ['close'],
    },
  },
  Html: {
    closeBtn: true,
  },
  Carousel: {
    Panzoom: false,
  },
  on: {
    reveal: (fancybox) => {
      const modelContainer = fancybox.container.querySelector(
        'div.model-container',
      )
      modelContainer.classList.remove('hidden')
      modelContainer.querySelector('model-viewer').dismissPoster()
    },
  },
})

const loadModel = function (obj) {
  let parent = obj.closest('section')
  if (parent == undefined) {
    parent = obj.closest('.CausesOfAction')
  }
  const modelContainer = parent.querySelector('div.model-container')
  modelContainer.classList.remove('hidden')
  parent.querySelector('model-viewer').dismissPoster()
}

window.loadModel = loadModel

const showAnnotations = function (obj) {
  const modelViewer = obj.parentElement

  const annotations = modelViewer.querySelectorAll('button.view-button')
  for (let i = 0; i < annotations.length; i++) {
    annotations[i].style.display = 'inline-block'
  }
  obj.classList.add('hidden')

  const modelInfo = modelViewer.nextElementSibling
  modelInfo.classList.remove('hidden')

  modelInfo.querySelectorAll('div.info-text').forEach((infoText) => {
    infoText.classList.add('hidden')
  })

  const modelIntroduction = modelInfo.querySelector('div.model-introduction')
  if (modelIntroduction.classList.contains('hidden')) {
    modelIntroduction.classList.remove('hidden')
  }

  const modelCountContainer = modelInfo.querySelector(
    'div.model-info-count-container',
  )
  if (!modelCountContainer.classList.contains('hidden')) {
    modelCountContainer.classList.add('hidden')
  }
}

window.showAnnotations = showAnnotations

const hideAnnotations = function (obj) {
  const modelInfo = obj.parentElement
  const modelViewer = modelInfo.previousSibling
  const annotations = modelViewer.querySelectorAll('button.view-button')
  const buttonShow = modelViewer.querySelector('button.show-annotations-button')

  for (let i = 0; i < annotations.length; i++) {
    annotations[i].style.display = 'none'
  }
  modelInfo.classList.add('hidden')
  buttonShow.classList.remove('hidden')
}

window.hideAnnotations = hideAnnotations

const setCamera = function (obj, a, b, c) {
  const modelViewerViewpoint = obj.closest('model-viewer')
  modelViewerViewpoint.cameraTarget = 'auto'
  modelViewerViewpoint.cameraOrbit = a + 'deg ' + b + 'deg ' + c + '%'
  modelViewerViewpoint.fieldOfView = 1.0
}

window.setCamera = setCamera

const mirrorScene = function (obj) {
  const modelViewer = obj.closest('model-viewer')
  const currentScale = modelViewer.scale.split(' ')
  modelViewer.scale =
    currentScale[0] * -1 + ' ' + currentScale[1] + ' ' + currentScale[2]

  const annotations = modelViewer.querySelectorAll('button.view-button')
  for (let i = 0; i < annotations.length; i++) {
    const name = annotations[i].getAttribute('slot')
    const orbit = annotations[i].getAttribute('data-orbit')
    const target = annotations[i].getAttribute('data-target')

    const hotspotData = modelViewer.queryHotspot(name)

    hotspotData.position.x = hotspotData.position.x * -1
    hotspotData.normal.x = hotspotData.normal.x * -1

    if (orbit[0] == '-') {
      annotations[i].setAttribute('data-orbit', orbit.slice(1))
    } else {
      annotations[i].setAttribute('data-orbit', '-' + orbit)
    }

    if (target != 'auto') {
      if (target[0] == '-') {
        annotations[i].setAttribute('data-target', target.slice(1))
      } else {
        annotations[i].setAttribute('data-target', '-' + target)
      }
    }

    modelViewer.updateHotspot({
      name: name,
      position: `${hotspotData.position.x} ${hotspotData.position.y} ${hotspotData.position.z}`,
      normal: `${hotspotData.normal.x} ${hotspotData.normal.y} ${hotspotData.normal.z}`,
    })
  }
}

window.mirrorScene = mirrorScene

// Load 3D Model
document.querySelectorAll('model-viewer').forEach((m) => {
  m.addEventListener('progress', (event) => {
    const progressinfo = m.querySelector('.model-progressinfo strong')
    const circle = m.querySelector('.model-progressring')
    const radius = circle.r.baseVal.value
    const circumference = radius * 2 * Math.PI
    circle.style.strokeDasharray = `${circumference} ${circumference}`
    circle.style.strokeDashoffset = `${circumference}`

    const offset = circumference - event.detail.totalProgress * circumference
    circle.style.strokeDashoffset = offset
    $(progressinfo).text(Math.round(event.detail.totalProgress * 100) + ' %')
  })
})

// Annotations
let actualAnnotationIndex = 0
document.querySelectorAll('button.view-button').forEach((a) => {
  a.addEventListener('click', () =>
    updateAnnotation(a, a.getAttribute('index'), 0),
  )
})
document.querySelectorAll('button.view-button-list').forEach((a) => {
  a.addEventListener('click', () =>
    updateAnnotation(a, a.getAttribute('index'), 0),
  )
})
document.querySelectorAll('button.model-info-back').forEach((a) => {
  a.addEventListener('click', () =>
    updateAnnotation(a, actualAnnotationIndex, -1),
  )
})
document.querySelectorAll('button.model-info-next').forEach((a) => {
  a.addEventListener('click', () =>
    updateAnnotation(a, actualAnnotationIndex, 1),
  )
})
document.querySelectorAll('.model-info-count-input').forEach((a) => {
  a.addEventListener('input', () => updateAnnotation(a, a.value, -1))
})

//Arrow keys
document.addEventListener('keydown', (event) => {
  document.querySelectorAll('.model-container').forEach((mc) => {
    if (!$(mc).hasClass('hidden')) {
      switch (event.key) {
        case 'Escape': // ESC
          $(mc).addClass('hidden')
          break
        case 'PageUp': // PageUp
          if (
            $(mc.querySelector('button.show-annotations-button')).hasClass(
              'hidden',
            )
          ) {
            updateAnnotation(mc, actualAnnotationIndex, -1)
          }
          break
        case 'PageDown': // PageDown
          if (
            $(mc.querySelector('button.show-annotations-button')).hasClass(
              'hidden',
            )
          ) {
            updateAnnotation(mc, actualAnnotationIndex, 1)
          }
          break
        case 'ArrowLeft': // left
          if (
            $(mc.querySelector('button.show-annotations-button')).hasClass(
              'hidden',
            )
          ) {
            updateAnnotation(mc, actualAnnotationIndex, -1)
          }
          break
        case 'ArrowRight': // right
          if (
            $(mc.querySelector('button.show-annotations-button')).hasClass(
              'hidden',
            )
          ) {
            updateAnnotation(mc, actualAnnotationIndex, 1)
          }
          break
        default:
          return // exit this handler for other keys
      }
      event.preventDefault() // prevent the default action (scroll / move caret)
    }
  })
})

const updateAnnotation = function (obj, i, change) {
  const modelContainer = obj.closest('div.model-container')
  const modelViewer = modelContainer.querySelector('model-viewer')
  const modelInfo = modelContainer.querySelector('.model-info')
  const modelInfoCountInput = modelInfo.querySelector('.model-info-count-input')
  const infos = modelInfo.querySelectorAll('.info-text')

  actualAnnotationIndex = parseInt(i) + change
  // Karusell in beide Richtungen
  if (actualAnnotationIndex < 0) {
    actualAnnotationIndex = infos.length - 1
  } else if (actualAnnotationIndex > infos.length - 1) {
    actualAnnotationIndex = 0
  }

  const annotations = modelViewer.querySelectorAll('button.view-button')
  const annotation = annotations[actualAnnotationIndex]

  const dataset = annotation.dataset
  modelViewer.cameraTarget = dataset.target
  modelViewer.cameraOrbit = dataset.orbit
  modelViewer.fieldOfView = annotation.getAttribute('fieldofview')
  //modelViewer.fieldOfView = '1.0';

  for (let i = 0; i < annotations.length; i++) {
    if (annotations[i].classList.contains('selected')) {
      annotations[i].classList.remove('selected')
    }
  }
  annotation.classList.add('selected')

  const info = infos[actualAnnotationIndex]
  for (let i = 0; i < infos.length; i++) {
    if (!infos[i].classList.contains('hidden')) {
      infos[i].classList.add('hidden')
    }
  }
  modelInfoCountInput.value = actualAnnotationIndex + 1
  info.classList.remove('hidden')
  modelInfo.classList.remove('hidden')

  const modelIntroduction = modelInfo.firstChild
  if (!modelIntroduction.classList.contains('hidden')) {
    modelIntroduction.classList.add('hidden')
  }

  const modelCountContainer = modelInfo.querySelector(
    'div.model-info-count-container',
  )
  if (modelCountContainer.classList.contains('hidden')) {
    modelCountContainer.classList.remove('hidden')
  }
}
