import { createApp } from 'vue'
import LoginDialog from './LoginDialog.vue'

const loginDialogElement = document.querySelector('.LoginDialog')
const openLoginDialogButtons = document.querySelectorAll('.js-login')
const openLoginUtilityElement = document.querySelectorAll('.Utility--openLogin')

if (loginDialogElement) {
  const loginDialogApp = createApp(LoginDialog).mount(loginDialogElement)
  window.loginDialogApp = loginDialogApp

  loginDialogElement.addEventListener('click', (event) => {
    if (event.target.nodeName === 'DIALOG') {
      window.loginDialogApp.close()
    }
  })

  for (let i = 0; i < openLoginDialogButtons.length; ++i) {
    openLoginDialogButtons[i].addEventListener('click', () => {
      loginDialogElement.showModal()
    })
  }

  if (openLoginUtilityElement.length) {
    loginDialogElement.showModal()
  }
}
