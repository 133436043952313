import { createApp } from 'vue'
import WatchListWidget from './WatchListWidget.vue'

const WatchListWidgetElement = document.querySelector('.WatchListWidget')

if (WatchListWidgetElement) {
  createApp(WatchListWidget, {
    pageId: WatchListWidgetElement.getAttribute('data-page'),
  }).mount(WatchListWidgetElement)
}
