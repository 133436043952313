import { createApp } from 'vue'
import EditProfilePassword from './EditProfilePassword.vue'

const EditProfilePasswordElement = document.querySelector(
  '.EditProfilePassword',
)

if (EditProfilePasswordElement) {
  createApp(EditProfilePassword).mount(EditProfilePasswordElement)
}
