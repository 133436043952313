import Chart from 'chart.js/auto'

const StatisticUserMetricsElement = document.querySelector(
  '.StatisticUserMetrics-users',
)

if (StatisticUserMetricsElement) {
  ;(async function () {
    Chart.defaults.color = 'rgba(157, 167, 175, 1)'
    Chart.defaults.font = {
      size: 12,
      family: 'Red Hat Text',
      weight: 500,
    }

    new Chart(StatisticUserMetricsElement, {
      type: 'line',
      options: {
        maintainAspectRatio: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxWidth: 6,
              boxHeight: 6,
              useBorderRadius: true,
              usePointStyle: true,
              pointStyle: 'line',
              borderRadius: 3,
              padding: 20,
              font: {
                size: 17,
                weight: 600,
              },
              color: 'hsl(205, 76%, 10%)',
            },
          },
        },
        scales: {
          x: {
            ticks: {
              autoSkip: true,
              maxRotation: 0,
              autoSkipPadding: 20,
            },
            grid: {
              display: false,
            },
            title: {
              color: 'rgba(157, 167, 175, 1)',
            },
          },
          // y: {
          //   grid: {
          //     beginAtZero: true,
          //   },
          //   ticks: {
          //     autoSkip: true,
          //     maxTicksLimit: 6,
          //   },
          // },
          y: {
            type: 'linear',
            display: true,
            position: 'left',
            // beginAtZero: true,
            grid: {
              // beginAtZero: true,
            },
            ticks: {
              autoSkip: true,
              // beginAtZero: true,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
          },
        },
      },
      data: {
        labels: document.jsVariables.userStatistic.labels,
        datasets: [
          {
            label:
              'Referenten: ' +
              document.jsVariables.userStatistic.users[
                document.jsVariables.userStatistic.users.length - 1
              ],
            data: document.jsVariables.userStatistic.users,
            tension: 0.1,
            borderColor: 'rgb(6, 29, 45)',
            borderWidth: 2,
            yAxisID: 'y',
          },
          {
            label:
              'Newsletter-Abonnenten: ' +
              document.jsVariables.userStatistic.newsletter[
                document.jsVariables.userStatistic.newsletter.length - 1
              ],
            data: document.jsVariables.userStatistic.newsletter,
            tension: 1,
            borderColor: 'rgb(61, 197, 255)',
            borderWidth: 2,
            yAxisID: 'y1',
          },
        ],
      },
    })
  })()
}

const StatisticUserMetricsProfessionsElement = document.querySelector(
  '.StatisticUserMetrics-professions',
)

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    const ul = getOrCreateLegendList(chart, options.containerID)

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove()
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart)

    items.forEach((item) => {
      const li = document.createElement('li')

      li.onclick = () => {
        const { type } = chart.config
        if (type === 'pie' || type === 'doughnut') {
          // Pie and doughnut charts only have a single dataset and visibility is per item
          chart.toggleDataVisibility(item.index)
        } else {
          chart.setDatasetVisibility(
            item.datasetIndex,
            !chart.isDatasetVisible(item.datasetIndex),
          )
        }
        chart.update()
      }

      // Color box
      const boxSpan = document.createElement('span')
      boxSpan.style.background = item.fillStyle

      // Text
      const textContainer = document.createElement('p')
      textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

      const text = document.createTextNode(item.text)
      textContainer.appendChild(text)

      li.appendChild(boxSpan)
      li.appendChild(textContainer)
      ul.appendChild(li)
    })
  },
}

if (StatisticUserMetricsProfessionsElement) {
  ;(async function () {
    Chart.defaults.color = 'rgba(157, 167, 175, 1)'
    Chart.defaults.font = {
      size: 12,
      family: 'Red Hat Text',
      weight: 500,
    }
    new Chart(StatisticUserMetricsProfessionsElement, {
      type: 'pie',
      plugins: [htmlLegendPlugin],
      options: {
        elements: {
          point: {
            radius: 0,
          },
        },
        layout: {
          padding: 0,
        },
        plugins: {
          htmlLegend: {
            // ID of the container to put the legend in
            containerID: 'statisticUserMetrics-professionsLegend',
          },
          legend: {
            display: false,
          },
        },
      },
      data: {
        labels: document.jsVariables.userStatistic.professionLabels,
        datasets: [
          {
            label: 'Berufsgruppe',
            data: document.jsVariables.userStatistic.professionValues,
            backgroundColor: [
              'rgba(6, 29, 45, 1)',
              'rgba(0, 125, 179, 1)',
              'rgba(61, 197, 255, 1)',
              'rgba(157, 167, 175, 0.6)',
              'rgba(157, 167, 175, 0.4)',
              'rgba(157, 167, 175, 0.2)',
            ],
            tension: 0.1,
            borderWidth: 0,
          },
        ],
      },
    })
  })()
}
