<template>
  <form
    ref="editProfileForm"
    class="EditProfileEmail --default"
    :class="{ '--validate': validate, '--pending': pending }"
    method="post"
    @submit.prevent="saveChanges()"
  >
    <div v-if="error" class="Message Message--error">{{ error }}</div>
    <div v-if="success" class="Message Message--success">{{ success }}</div>

    <h2>Email-Adresse ändern</h2>

    <p>
      Zum Ändern Ihrer Email-Adresse erhalten Sie eine Bestätigungsmail an die
      von Ihnen unten festzulegende neue Email-Adresse. Erst nach Klick auf den
      Bestätigungslink wird die Email geändert.
    </p>

    <p><b>Aktuelle Email-Adresse:</b> {{ currentEmail }}</p>

    <input-field
      v-for="field in fields"
      :key="field.name"
      v-model="field.modelValue"
      :hide-field="hideField(field)"
      form-name="EditProfileEmail"
      v-bind="field"
      :is-disabled="pending"
      :is-validation-active="validate"
      :is-custom-invalid="isCustomInvalid(field)"
      :class="{
        'EditProfileEmail-field': true,
        'EditProfileEmail-field--textarea': field.type == 'textarea',
        'EditProfileEmail-field--default': field.type != 'textarea',
      }"
    />

    <div class="EditProfileEmail-buttons">
      <button
        class="blue-button"
        :disabled="pending"
        type="submit"
        @click="validate = true"
      >
        Speichern
      </button>
      <a v-if="linkBack" class="button" :href="linkBack">zurück</a>
    </div>
  </form>
</template>

<script>
import InputField from '../InputField/InputField.vue'
import axios from 'axios'

export default {
  components: {
    InputField,
  },
  data() {
    return {
      fields: [
        {
          name: 'email',
          label: 'Neue Email-Adresse',
          fieldType: 'email',
          required: true,
        },
      ],
      error: false,
      success: false,
      pending: false,
      currentEmail: document.jsVariables.editProfileEmail,
      linkBack: document.jsVariables.linkBack,
      validate: false,
    }
  },
  methods: {
    saveChanges() {
      this.error = false
      this.success = false
      this.validate = true

      if (this.checkForCustomErrors() == false) {
        this.sendForm()
      }
    },
    hideField(field) {
      if (field.showOnlyIf == undefined) return false
      const name = field.showOnlyIf.name
      const values = field.showOnlyIf.value
      const currentValue = this.fields.find((el) => el.name == name).modelValue

      if (values.includes(currentValue)) return false

      return true
    },
    checkForCustomErrors() {
      let errors = false
      this.fields.forEach((field) => {
        if (this.isCustomInvalid(field)) errors = true
      })

      return errors
    },
    isCustomInvalid() {
      return false
    },
    sendForm() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue
      const formData = new FormData(this.$refs.editProfileForm)

      this.validate = false
      this.pending = true

      axios
        .post(document.jsVariables.apiBaseUrl + 'updateEmail', formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.success =
              'Sie erhalten in Kürze eine Email zu Bestätigung Ihrer neuen Email-Adresse. Bitte klicken Sie auf den enthaltenen Link, um Ihre neue Adresse zu aktivieren.'

            this.fields.forEach((field) => {
              field.modelValue = ''
            })
          }
        })
        .catch((error) => {
          this.pending = false
          if (error.response.status == 500) {
            this.error =
              'Leider ging bei der Verarbeitung des Formulares etwas schief. Versuchen Sie es bitte in Kürze erneut. Sollte es weiterhin nicht funktionieren, geben Sie uns gerne Bescheid.'
          } else {
            this.error = error.response.data
          }
        })
    },
  },
}
</script>
