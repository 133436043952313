import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

import Plyr from 'plyr/dist/plyr.min.js'
// import * as Plyr from 'plyr'
import '@/node_modules/plyr/dist/plyr.css'
import videoTranslations from '@/js/misc/plyr-translation.js'

function initVideoSlide(fancybox) {
  const slide = fancybox.getSlide()

  if (slide.el == null) return

  if (fancybox.isCurrentSlide(slide) && slide.type == 'html5video') {
    new Plyr(slide.contentEl.querySelector('.Video-video'), {
      controls: [
        'progress',
        'play-large',
        'play',
        'mute',
        'volume',
        'current-time',
        'duration',

        'captions',
        'settings',
        'fullscreen',
      ],
      i18n: videoTranslations,
      resetOnEnd: true,
      language: 'de',
    })
  }
}

Fancybox.bind('[data-fullscreen-gallery]', {
  // Your custom options
  groupAttr: 'data-fullscreen-gallery',

  Toolbar: {
    enabled: true,
    display: {
      left: ['infobar', 'download'],
      middle: ['zoomIn', 'zoomOut', 'toggle1to1'],
      right: ['slideshow', 'thumbs', 'close'],
    },
  },

  Html: {
    closeBtn: true,
    videoAutoplay: false,
    videoTpl: `<div class="Video"><video class="Video-video" preload="none" loop playsinline muted controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn't support embedded videos.</video></div>`,
  },

  on: {
    shouldClose: (fancybox) => {
      const index = Fancybox.getSlide().index
      const galleryId = fancybox.userSlides[0].fullscreenGallery

      const button = document.querySelector(
        '.Gallery-button[aria-controls="' + galleryId + '"]',
      )
      const gallery = document.querySelector('#' + galleryId)

      if (
        button.getAttribute('aria-expanded') == 'false' &&
        gallery.children[index].checkVisibility() === false
      ) {
        button.setAttribute('aria-expanded', 'true')
        button.innerHTML = button.getAttribute('data-expanded-text')
        gallery.setAttribute('data-expanded', 'true')
      }
    },
    'Carousel.change': (fancybox) => {
      // Current slide
      initVideoSlide(fancybox)
    },
    init: (fancybox) => {
      if (
        fancybox.container &&
        fancybox.container.querySelector('[data-fancybox-download]')
      ) {
        fancybox.container.querySelector(
          '[data-fancybox-download]',
        ).style.display = 'none'
      }
    },
    reveal: (fancybox, slide) => {
      // Current slide
      initVideoSlide(fancybox)

      if (!slide.triggerEl.getAttribute('data-download-src')) {
        if (fancybox.container.querySelector('[data-fancybox-download]')) {
          fancybox.container.querySelector(
            '[data-fancybox-download]',
          ).style.display = 'none'
        }
      } else {
        fancybox.container.querySelector(
          '[data-fancybox-download]',
        ).style.display = 'flex'
      }
    },
  },
})

// if more than more => on slideshow close focus

const galleryMoreButtons = document.querySelectorAll('.Gallery-button')

function checkVisibilityOfLastGalleryItem(button) {
  const galleryId = button.getAttribute('aria-controls')
  const gallery = document.querySelector('#' + galleryId)
  const lastGalleryItemVisibility = gallery
    .querySelector('.Gallery-item:last-of-type')
    .checkVisibility()

  if (lastGalleryItemVisibility == true) {
    button.style.display = 'none'
    gallery.setAttribute('aria-expanded', 'true')
  } else {
    button.style.display = 'flex'
    gallery.setAttribute('aria-expanded', 'false')
  }
}

Array.prototype.forEach.call(galleryMoreButtons, function (button) {
  button.addEventListener('click', () => {
    const state = button.getAttribute('aria-expanded')
    const galleryId = button.getAttribute('aria-controls')
    const gallery = document.querySelector('#' + galleryId)

    if (state == 'true') {
      button.setAttribute('aria-expanded', 'false')
      button.innerHTML = button.getAttribute('data-collapsed-text')
      gallery.setAttribute('data-expanded', 'false')
    } else {
      button.setAttribute('aria-expanded', 'true')
      button.innerHTML = button.getAttribute('data-expanded-text')
      gallery.setAttribute('data-expanded', 'true')
    }
  })

  checkVisibilityOfLastGalleryItem(button)
})

window.addEventListener('resize', () => {
  Array.prototype.forEach.call(galleryMoreButtons, function (button) {
    checkVisibilityOfLastGalleryItem(button)
  })
})
