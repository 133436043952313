import Plyr from 'plyr/dist/plyr.min.js'
// import * as Plyr from 'plyr'
import '@/node_modules/plyr/dist/plyr.css'
import videoTranslations from '@/js/misc/plyr-translation.js'

const VideoElements = document.querySelectorAll('.Video-video')

Array.prototype.forEach.call(VideoElements, function (videoElement) {
  new Plyr(videoElement, {
    controls: [
      'progress',
      'play-large',
      'play',
      'mute',
      'volume',
      'current-time',
      'duration',

      'captions',
      'settings',
      videoElement.getAttribute('data-download') ? 'download' : '',
      'fullscreen',
    ],
    i18n: videoTranslations,
    resetOnEnd: true,
    language: 'de',
  })
})
