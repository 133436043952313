<template>
  <button
    class="WatchListIndicator-button"
    :title="
      isOnWatchList ? 'Von Merkliste entfernen' : 'Zur Merkliste hinzufügen'
    "
    :class="{
      'WatchListIndicator-button--pending': pending == true,
      'WatchListIndicator-button--active': isOnWatchList == true,
      'WatchListIndicator-button--initial': isInitial == true,
    }"
    @click.prevent="toogleWatchListItem()"
  >
    <svg
      width="23"
      height="30"
      viewBox="0 0 23 30"
      fill="none"
      aria-hidden="true"
      class="WatchListIndicator-buttonIcon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-if="isOnWatchList"
        d="M0.62207 0.509766H22.9998V29.1035L11.8109 20.7119L0.62207 29.1035V0.509766Z"
      />

      <path
        v-if="!isOnWatchList"
        d="M0.170898 0.509766H22.5486V29.1035L11.3598 20.7119L0.170898 29.1035V0.509766ZM2.65731 2.99618V24.1307L11.3598 17.6039L20.0622 24.1307V2.99618H2.65731Z"
      />
    </svg>
  </button>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    id: {
      type: Number,
      required: true,
      default: 1,
    },
    type: {
      type: String,
      required: true,
      default: 'page',
    },
  },
  data() {
    return {
      pending: false,
      isOnWatchList: false,
      isInitial: true,
    }
  },
  mounted() {
    this.getWatchListItemStatus()
  },
  methods: {
    getWatchListItemStatus() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      this.pending = true

      axios
        .post(
          document.jsVariables.apiBaseUrl + 'getWatchListItemStatus',
          { type: this.type, id: this.id },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then((response) => {
          this.pending = false
          this.isInitial = false
          if (response.status == 200) {
            this.isOnWatchList = response.data.status
          }
        })
    },
    toogleWatchListItem() {
      if (this.pending) return

      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      this.pending = true

      axios
        .post(
          document.jsVariables.apiBaseUrl + 'toggleWatchListItemStatus',
          { type: this.type, id: this.id },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.isOnWatchList = response.data.status
          }
        })
    },
  },
}
</script>
