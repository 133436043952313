import { createApp } from 'vue'
import ReferentsMap from './ReferentsMap.vue'

const ReferentsMapElement = document.querySelector('.ReferentsMap')

import { Fancybox } from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'

if (ReferentsMapElement) {
  createApp(ReferentsMap).mount(ReferentsMapElement)

  Fancybox.bind('[data-referentsmap-modal]', {
    hideClass: false,
    Toolbar: {
      enabled: true,
      display: {
        right: ['close'],
      },
    },
    Html: {
      closeBtn: true,
      autoSize: false,
    },
    Carousel: {
      Panzoom: false,
    },
    defaultType: 'inline',
  })
}
