<template>
  <form
    ref="editProfileForm"
    class="EditProfilePassword --default"
    :class="{ '--validate': validate, '--pending': pending }"
    method="post"
    @submit.prevent="saveChanges()"
  >
    <div v-if="error" class="Message Message--error">{{ error }}</div>
    <div v-if="success" class="Message Message--success">{{ success }}</div>

    <h2>Passwort ändern</h2>

    <input-field
      v-for="field in fields"
      :key="field.name"
      v-model="field.modelValue"
      :hide-field="hideField(field)"
      form-name="EditProfilePassword"
      v-bind="field"
      :is-disabled="pending"
      :is-validation-active="validate"
      :is-custom-invalid="isCustomInvalid(field)"
      :class="{
        'EditProfilePassword-field': true,
        'EditProfilePassword-field--textarea': field.type == 'textarea',
        'EditProfilePassword-field--default': field.type != 'textarea',
      }"
    />

    <button
      class="blue-button"
      :disabled="pending"
      type="submit"
      @click="validate = true"
    >
      Speichern
    </button>
    <a v-if="linkBack" class="button" :href="linkBack">zurück</a>
  </form>
</template>

<script>
import InputField from '../InputField/InputField.vue'
import axios from 'axios'

export default {
  components: {
    InputField,
  },
  data() {
    return {
      fields: [
        {
          name: 'passwordOld',
          label: 'Aktuelles Passwort',
          fieldType: 'password',
          required: true,
        },
        {
          name: 'password',
          label: 'Neues Passwort',
          fieldType: 'password',
          required: true,
        },
        {
          name: 'passwordConfirm',
          label: 'Neues Passwort bestätigen',
          customErrorMessage:
            'Die von Ihnen eingegebenen Passwörter stimmen nicht überein.',
          fieldType: 'password',
          required: true,
        },
      ],
      error: false,
      success: false,
      pending: false,
      view: 'login',
      validate: false,
      linkBack: document.jsVariables.linkBack,
    }
  },
  methods: {
    saveChanges() {
      this.error = false
      this.success = false
      this.validate = true

      if (this.checkForCustomErrors() == false) {
        this.sendForm()
      }
    },
    hideField(field) {
      if (field.showOnlyIf == undefined) return false
      const name = field.showOnlyIf.name
      const values = field.showOnlyIf.value
      const currentValue = this.fields.find((el) => el.name == name).modelValue

      if (values.includes(currentValue)) return false

      return true
    },
    checkForCustomErrors() {
      let errors = false
      this.fields.forEach((field) => {
        if (this.isCustomInvalid(field)) errors = true
      })

      return errors
    },
    isPasswordConfirmValid() {
      const password = this.fields.find(
        (el) => el.name == 'password',
      ).modelValue
      const passwordConfirm = this.fields.find(
        (el) => el.name == 'passwordConfirm',
      ).modelValue

      if (passwordConfirm == undefined && password == undefined) return true
      if (passwordConfirm !== '' && passwordConfirm !== password) return false
      return true
    },
    isCustomInvalid(field) {
      if (
        this.validate &&
        field.name == 'passwordConfirm' &&
        !this.isPasswordConfirmValid()
      )
        return true

      return false
    },
    sendForm() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue
      const formData = new FormData(this.$refs.editProfileForm)

      this.validate = false
      this.pending = true

      axios
        .post(document.jsVariables.apiBaseUrl + 'updatePassword', formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.success = 'Ihr Passwort wurde erfolgreich geändert.'

            this.fields.forEach((field) => {
              field.modelValue = ''
            })
          }
        })
        .catch((error) => {
          this.pending = false
          if (error.response.status == 500) {
            this.error =
              'Leider ging bei der Verarbeitung des Formulares etwas schief. Versuchen Sie es bitte in Kürze erneut. Sollte es weiterhin nicht funktionieren, geben Sie uns gerne Bescheid.'
          } else {
            this.error = error.response.data
          }
        })
    },
  },
}
</script>
