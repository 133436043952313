import { createApp } from 'vue'
import WatchListItemRemove from './WatchListItemRemove.vue'
import Sortable from 'sortablejs'
import axios from 'axios'

const watchListElement = document.querySelector('.WatchList-items')

if (watchListElement) {
  const saveWatchListOrder = async (order) => {
    try {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      await axios
        .post(
          document.jsVariables.apiBaseUrl + 'saveWatchListOrder',
          { order: order },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              [tokenName]: tokenValue,
            },
          },
        )
        .then(() => {
          watchListElement.classList.remove('WatchList-items--pending')
        })
    } catch (error) {
      watchListElement.classList.remove('WatchList-items--pending')
      console.log(error)
    }
  }

  Sortable.create(watchListElement, {
    invertSwap: true,
    onEnd: function () {
      watchListElement.classList.add('WatchList-items--pending')

      const items = document.querySelectorAll('.WatchList-item')
      const newOrder = []

      items.forEach((item, newIndex) => {
        const oldIndex = parseInt(item.getAttribute('data-index'))
        newOrder.push({
          old: oldIndex,
          new: newIndex,
        })
      })

      const newOrderNormalized = newOrder
        .sort((a, b) => a.old - b.old)
        .map((item) => item.new)

      saveWatchListOrder(newOrderNormalized)
    },
  })

  const watchListRemoveButtons = document.querySelectorAll(
    '.WatchList-itemRemove',
  )

  if (watchListRemoveButtons) {
    for (let i = 0; i < watchListRemoveButtons.length; ++i) {
      createApp(WatchListItemRemove, {
        id: watchListRemoveButtons[i].getAttribute('data-id'),
      }).mount(watchListRemoveButtons[i])
    }
  }
}
