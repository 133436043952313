<template>
  <form
    ref="editProfileForm"
    class="EditProfile-form --default"
    :class="{ '--validate': validate, '--pending': pending }"
    method="post"
    @submit.prevent="saveChanges()"
  >
    <div v-if="error" class="Message Message--error">{{ error }}</div>
    <div v-if="success" class="Message Message--success">{{ success }}</div>

    <input-field
      v-for="field in fields"
      :key="field.name"
      v-model="field.modelValue"
      :hide-field="hideField(field)"
      form-name="EditProfile"
      v-bind="field"
      :is-disabled="pending"
      :is-validation-active="validate"
      :class="{
        'EditProfile-field': true,
        'EditProfile-field--textarea': field.type == 'textarea',
        'EditProfile-field--default': field.type != 'textarea',
      }"
    />

    <div class="EditProfile-buttons">
      <button
        class="blue-button"
        :disabled="pending"
        type="submit"
        @click="validate = true"
      >
        Speichern
      </button>
      <a v-if="linkBack" class="button" :href="linkBack">zurück</a>
    </div>
  </form>
</template>

<script>
import InputField from '../InputField/InputField.vue'
import axios from 'axios'

export default {
  components: {
    InputField,
  },
  data() {
    return {
      fields: document.jsVariables.editProfileFields,
      linkBack: document.jsVariables.linkBack,
      error: false,
      success: false,
      pending: false,
      view: 'login',
      validate: false,
    }
  },
  methods: {
    saveChanges() {
      this.error = false
      this.success = false
      this.validate = true

      if (this.checkForCustomErrors() == false) {
        this.sendForm()
      }
    },
    hideField(field) {
      if (field.showOnlyIf == undefined) return false
      const name = field.showOnlyIf.name
      const values = field.showOnlyIf.value
      const currentValue = this.fields.find((el) => el.name == name).modelValue

      if (values.includes(currentValue)) return false

      return true
    },
    checkForCustomErrors() {
      return false
    },
    updateFields() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue

      axios
        .get(document.jsVariables.apiBaseUrl + 'getProfileData', {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.fields = response.data
          }
        })
    },
    sendForm() {
      const tokenName = 'X-' + document.csrfTokenName
      const tokenValue = document.csrfTokenValue
      const formData = new FormData(this.$refs.editProfileForm)

      this.validate = false
      this.pending = true

      axios
        .post(document.jsVariables.apiBaseUrl + 'editProfile', formData, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            [tokenName]: tokenValue,
          },
        })
        .then((response) => {
          this.pending = false
          if (response.status == 200) {
            this.success = 'Ihre Änderungen wurden gespeichert.'
            this.updateFields()
          }
        })
        .catch((error) => {
          this.pending = false
          if (error.response.status == 500) {
            this.error =
              'Leider ging bei der Verarbeitung des Formulares etwas schief. Versuchen Sie es bitte in Kürze erneut. Sollte es weiterhin nicht funktionieren, geben Sie uns gerne Bescheid.'
          } else {
            this.error = error.response.data
          }
        })
    },
  },
}
</script>
