<template>
  <div class="ReferentsMap">
    <div id="referentsmap" class="ReferentsMap-map"></div>
    <form method="post" class="ReferentsMap-selection">
      <button
        value="all"
        type="submit"
        class="ReferentsMap-button"
        name="profession"
        :class="{
          'ReferentsMap-button--active': currentProfession == 'all',
        }"
      >
        Alle <span>{{ totalCount }}</span>
      </button>
      <button
        v-for="(item, index) in professions"
        :key="index"
        :value="item.id"
        name="profession"
        class="ReferentsMap-button"
        :class="{
          'ReferentsMap-button--active': currentProfession == item.id,
        }"
        type="submit"
      >
        {{ item.label }} <span>{{ item.count }}</span>
      </button>
    </form>
    <div class="ReferentsMap-info">{{ currentTitle }}</div>
  </div>
</template>

<script>
import { Loader } from 'google-maps'
import MapStyles from './MapStyles'

let loader = false

const mapOptions = {
  zoom: 5.8,
  disableDefaultUI: true,
  draggable: true,
  styles: MapStyles,
  options: {
    gestureHandling: 'greedy',
  },
}

export default {
  name: 'ReferentsMap',

  data() {
    return {
      allowed: false,
      currentTitle: '',
      professions: document.jsVariables.referentsMapProfession,
      currentProfession: document.jsVariables.currentProfession,
      totalCount: document.jsVariables.referentsTotal,
    }
  },

  created() {
    loader = new Loader('AIzaSyBkmA9kmt_HRwAJ4og4xN8yFg2D4XIIh7c')
  },

  mounted() {
    this.load()
  },
  methods: {
    load() {
      loader.load().then((google) => {
        const markerIcon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: '#061d2d',
          fillOpacity: 0.25,
          strokeWeight: 0,
        }

        const currentIcon = {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 6,
          fillColor: '#3dc5ff',
          fillOpacity: 1,
          strokeWeight: 0,
        }

        const center = new google.maps.LatLng(51.627743, 9.3128329)
        const map = new google.maps.Map(
          document.getElementById('referentsmap'),
          {
            ...mapOptions,
            center: center,
          },
        )

        document.jsVariables.referentsMap.forEach((referent) => {
          let tempIcon = markerIcon

          const registrationDate = new Date(referent.date)
          const currentDateMinimum = new Date()
          currentDateMinimum.setDate(currentDateMinimum.getDate() - 14)

          if (registrationDate > currentDateMinimum) {
            tempIcon = currentIcon
          }

          const marker = new google.maps.Marker({ // eslint-disable-line
            position: new google.maps.LatLng(
              Number(referent.lat),
              Number(referent.lng),
            ),
            map: map,
            title:
              referent.name +
              ' | ' +
              referent.street +
              ' | ' +
              referent.postcode +
              ' | ' +
              referent.place +
              ' | #' +
              referent.id,
            icon: { ...tempIcon },
          })

          marker.addListener('click', () => {
            this.currentTitle = marker.title
          })

          google.maps.event.addDomListener(window, 'resize', function () {
            const center = map.getCenter()
            google.maps.event.trigger(map, 'resize')
            map.setCenter(center)
          })
        })
      })
    },
  },
}
</script>

<style lang="scss">
.map {
  width: 100%;
  height: 100%;
}
</style>
