import $ from 'jquery'

let mouseX, mouseY
$(document).mousemove(function (e) {
  mouseX = e.pageX
  mouseY = e.pageY
})

// Tabellen auf Seitenbreite beschränken (Mobil)
$('table').wrap('<div class="table-container"></div>')

// TEXTAREA FOLD +-
$(window).ready(function () {
  checkTargets()
  $('a[href*=\\#]').on('click', function (event) {
    if (this.pathname === window.location.pathname) {
      window.setTimeout(checkTargets, 10)
    }
  })
  $('.fold-button').click(function () {
    if ($(this).hasClass('unfolded')) {
      foldChapter(this, false)
    } else {
      foldChapter(this, true)
    }
  })
})
function checkTargets() {
  let target = null
  target = $(':target')
  if (target) {
    const fold = target.find('.fold-button')[0]
    if (fold) {
      foldChapter(fold, true)
    }
  }
}
function foldChapter(obj, unfolded) {
  const content = obj.nextElementSibling
  if (!unfolded) {
    content.style.maxHeight = null
    $(obj).removeClass('unfolded').addClass('folded')
  } else {
    content.style.maxHeight = content.scrollHeight + 20 + 'px'
    $(obj).removeClass('folded').addClass('unfolded')
  }
}
$(window).resize(function () {
  $('.fold-content').each(function () {
    if (this.style.maxHeight != '0px' && this.style.maxHeight != '') {
      this.style.maxHeight = this.scrollHeight + 20 + 'px'
    }
  })
})

// TEXTAREA FOLD ARROW
/*$(".fold-button").click(function(event){
    var arrow = $(this).find("img").first();
    var content = this.nextElementSibling;
    if (content.style.maxHeight){
      content.style.maxHeight = null;
      arrow.css("transform", "rotate(180deg)");
    } else {
      content.style.maxHeight = (content.scrollHeight + 20) + "px";
      arrow.css("transform", "rotate(0deg)");
    }
});*/

// GALLERY SHOW MORE
$('.gallery-cell.more').click(function (event) {
  /*
    if($(this).parent().hasClass("folded")){
        $(this).parent().removeClass("folded");
        $(this).parent().addClass("unfolded");
    }else{
        $(this).parent().removeClass("unfolded");
        $(this).parent().addClass("folded");
    }*/
  const images = $(this).parent().children()
  images.each(function () {
    if ($(this).css('display') == 'none') {
      if (!$(this).hasClass('hidden-image')) {
        $(this).addClass('hidden-image')
      }
      $(this).css('display', 'block')
    } else if (
      $(this).css('display') == 'block' &&
      $(this).hasClass('hidden-image')
    ) {
      $(this).css('display', 'none')
    }
  })
  // Change Button text
  const text = $(this).find('span').first()
  if (text.text() == 'Alles anzeigen') {
    text.text('Weniger anzeigen')
  } else {
    text.text('Alles anzeigen')
  }
})

// Video Loader
/*$("button.video-load-button").click(function(){loadVideo(this);}); 
function loadVideo(obj){
    var parent = $(obj).parent();
    var src = parent.attr("src");
    var type = parent.attr("type");
    var video = parent.find("video").first();
    video.attr("src",src);
    video.attr("type",type);
    video.attr('controls',true);
    $(obj).css("display", "none");
}*/

// FULLSCREEN IMAGE SHOW SCRIPT
let actualFullscreen = null
let actualFullscreenIndex = 0
let fullscreenArray
//Open and close Fullscreen Gallery
$(window).ready(function () {
  fullscreenArray = $('.fullscreen')
  fullscreenArray.click(function () {
    openFullscreen(this)
  })
})

/*
//Mobile Swipe with Jquery mobile
$("#fullscreen-image-container").on("swipeleft", swipeleftHandler );
$("#fullscreen-image-container").on("swiperight", swiperightHandler );
function swipeleftHandler( event ){ nextFullscreen();}
function swiperightHandler( event ){ previousFullscreen();}
*/
function openFullscreen(obj) {
  fullscreenArray = $(obj).parent().parent().find('.fullscreen')
  actualFullscreen = $(obj)
  actualFullscreenIndex = fullscreenArray.index($(obj))
  $('#fullscreen-image').remove()
  $('#fullscreen-video').remove()
  updateFullscreen()
  $('#fullscreen').removeClass('hidden')
}

function closeFullscreen() {
  $('#fullscreen').addClass('hidden')
  $('#fullscreen-image').remove()
  $('#fullscreen-video').remove()
}
function previousFullscreen() {
  actualFullscreenIndex--
  updateFullscreen()
}

window.previousFullscreen = previousFullscreen

function nextFullscreen() {
  actualFullscreenIndex++
  updateFullscreen()
}

window.nextFullscreen = nextFullscreen

function updateFullscreen() {
  if (actualFullscreenIndex > fullscreenArray.length - 1) {
    actualFullscreenIndex = fullscreenArray.length - 1
  } else if (actualFullscreenIndex < 0) {
    actualFullscreenIndex = 0
  }
  actualFullscreen = fullscreenArray.eq(actualFullscreenIndex)

  if (actualFullscreen.attr('video')) {
    if ($('#fullscreen-video').length == 0) {
      const video =
        "<video id='fullscreen-video' width='1920' height='1080' controls muted loop poster><source id='fullscreen-video-src' src='' type='video/mp4'>Your browser does not support the video tag.</video>"
      $('#fullscreen-container-container').append(video)
    }
    $('#fullscreen-video').css('display', 'flex')
    $('#fullscreen-description p').css('display', 'flex')
    var txt = actualFullscreen.attr('alt')
    var txt_count =
      'Video ' + (actualFullscreenIndex + 1) + ' von ' + fullscreenArray.length
    $('#fullscreen-video-src').attr('src', actualFullscreen.attr('video'))
    $('#fullscreen-video').attr('poster', actualFullscreen.attr('large'))
    $('#fullscreen-video')[0].load()
    if (txt == '') {
      $('#fullscreen-description p').css('display', 'none')
    } else {
      $('#fullscreen-description p').text(txt)
    }
    $('#fullscreen-count').text(txt_count)
  } else {
    if ($('#fullscreen-image').length == 0) {
      const img = "<img id='fullscreen-image'>"
      $('#fullscreen-container-container').append(img)
    }
    $('#fullscreen-image').css('display', 'flex')
    $('#fullscreen-description p').css('display', 'flex')
    let url
    if (actualFullscreen.prop('tagName') == 'IMG') {
      url = actualFullscreen.attr('large')
    } else {
      url = actualFullscreen.css('background-image')
    }
    var txt = actualFullscreen.attr('alt')
    var txt_count =
      'Bild ' + (actualFullscreenIndex + 1) + ' von ' + fullscreenArray.length
    $('#fullscreen-image').attr('src', '')
    $('#fullscreen-image').attr('src', url)
    if (txt == '') {
      $('#fullscreen-description p').css('display', 'none')
    } else {
      $('#fullscreen-description p').text(txt)
    }
    $('#fullscreen-count').text(txt_count)
  }

  if (actualFullscreenIndex == 0) {
    $('#fullscreen .previous').css('opacity', '0')
  } else {
    $('#fullscreen .previous').css('opacity', '1')
  }
  if (actualFullscreenIndex == fullscreenArray.length - 1) {
    $('#fullscreen .next').css('opacity', '0')
  } else {
    $('#fullscreen .next').css('opacity', '1')
  }
}

//Arrow keys
document.addEventListener('keydown', (event) => {
  if ($('#fullscreen').css('display') == 'flex') {
    switch (event.key) {
      case 'Escape': // ESC
        closeFullscreen()
        break
      case 'PageUp': // PageUp
        actualFullscreenIndex--
        updateFullscreen()
        break
      case 'PageDown': // PageDown
        actualFullscreenIndex++
        updateFullscreen()
        break
      case 'ArrowLeft': // left
        actualFullscreenIndex--
        updateFullscreen()
        break
      case 'ArrowRight': // right
        actualFullscreenIndex++
        updateFullscreen()
        break
      default:
        return // exit this handler for other keys
    }
    event.preventDefault() // prevent the default action (scroll / move caret)
  }
})

// Team Fullscreen
let teamMember
let teamFullscreen
let actualTeamMember
$(window).ready(function () {
  teamMember = $('.team-member')
  teamFullscreen = $('.team-fullscreen')
  for (var i = 0; i < teamMember.length; i++) {
    teamMember[i].addEventListener('click', function () {
      actualTeamMember = teamMember.index($(this))
      $(teamFullscreen[actualTeamMember]).removeClass('hidden')
    })
  }
  for (var i = 0; i < teamFullscreen.length; i++) {
    teamFullscreen[i].addEventListener('click', function () {
      $(this).addClass('hidden')
    })
    $('.team-item')[i].addEventListener('click', function (event) {
      event.stopPropagation()
    })
  }

  //Previous & Next
  const previousButtons = $('.team-fullscreen .previous')
  Array.from(previousButtons).forEach(function (b) {
    b.addEventListener('click', function () {
      previousTeamMember()
    })
  })
  const nextButtons = $('.team-fullscreen .next')
  Array.from(nextButtons).forEach(function (b) {
    b.addEventListener('click', function () {
      nextTeamMember()
    })
  })
  function previousTeamMember() {
    $(teamFullscreen[actualTeamMember]).addClass('hidden')
    actualTeamMember -= 1
    if (actualTeamMember < 0) actualTeamMember = teamMember.length - 1
    $(teamFullscreen[actualTeamMember]).removeClass('hidden')
  }
  function nextTeamMember() {
    $(teamFullscreen[actualTeamMember]).addClass('hidden')
    actualTeamMember += 1
    if (actualTeamMember >= teamMember.length) actualTeamMember = 0
    $(teamFullscreen[actualTeamMember]).removeClass('hidden')
  }

  //Arrow keys
  document.addEventListener('keydown', (event) => {
    if ($('.team-fullscreen').length > $('.team-fullscreen.hidden').length) {
      switch (event.key) {
        case 'Escape': // ESC
          $($(".team-fullscreen:not('.hidden')")).addClass('hidden')
          break
        case 'ArrowLeft':
          previousTeamMember()
          break
        case 'ArrowRight':
          nextTeamMember()
          break
      }
    }
  })
})

// Botschafter
let actualBotschafter = 0
let botschafter, dots, botschafterContainer, botschafterAutoplay
const botschafterTime = 7500
$(window).ready(function () {
  if ($('.botschafter').length > 0) {
    botschafterContainer = $('.botschafterContainer')[0]
    botschafter = $('.botschafter')
    dots = $('#start-botschafter .dot')
    dots.click(function () {
      actualBotschafter = $(this).index()
      updateBotschafter()
      clearInterval(botschafterAutoplay)
      botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
    })
    $('#start-botschafter .next').click(function () {
      nextBotschafter()
      clearInterval(botschafterAutoplay)
      botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
    })
    $('#start-botschafter .previous').click(function () {
      previousBotschafter()
      clearInterval(botschafterAutoplay)
      botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
    })

    $('.botschafter').on('swipeleft', function () {
      nextBotschafter()
      clearInterval(botschafterAutoplay)
      botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
    })
    $('.botschafter').on('swiperight', function () {
      previousBotschafter()
      clearInterval(botschafterAutoplay)
      botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
    })

    if (botschafter.length > 0) {
      updateBotschafter()
    }
    botschafterAutoplay = setInterval(nextBotschafter, botschafterTime)
  }
})
function previousBotschafter() {
  actualBotschafter--
  updateBotschafter()
}
function nextBotschafter() {
  actualBotschafter++
  updateBotschafter()
}
function updateBotschafter() {
  if (actualBotschafter >= botschafter.length) {
    actualBotschafter = 0
  }
  if (actualBotschafter < 0) {
    actualBotschafter = botschafter.length - 1
  }
  $(dots).each(function () {
    if (this == dots[actualBotschafter]) {
      if (!$(this).hasClass('current')) $(this).addClass('current')
    } else {
      if ($(this).hasClass('current')) $(this).removeClass('current')
    }
  })
  $(botschafter).each(function () {
    if (this == botschafter[actualBotschafter]) {
      $(this).css('opacity', 1)
    } else {
      $(this).css('opacity', 0)
    }
  })
  let offset = $($('.botschafter')[actualBotschafter]).offset().left
  if (window.innerWidth > 750) {
    offset -= $('#menu').outerWidth()
    // console.log('desktop')
  }
  botschafterContainer.scrollLeft += offset
}
$(window).resize(function () {
  if ($('.botschafter').length > 0) {
    updateBotschafter()
  }
})

// Searching Animation
$(window).ready(function () {
  $('.search-button').click(function () {
    showSearching()
  })
})
function showSearching() {
  $('#searching').css('display', 'flex')
  $('#searching').hide()
  $('#searching').fadeIn(500)
}

// Button .open-cases
$(window).ready(function () {
  $('button.open-cases').click(function () {
    $('.handlungsanlaesse').each(function () {
      if ($(this).hasClass('hidden')) {
        $(this).removeClass('hidden')
      }
    })
  })
})

// Button .close
$(window).ready(function () {
  $('button.close').click(function () {
    $(this).parent().addClass('hidden')
  })

  // Enable Submittbutton
  $('.enable-submit-button').click(function () {
    const submitButton = $(this).next()
    $(this).addClass('hidden')
    $(this).closest('form').addClass('--validate')
    $(submitButton).replaceWith(
      "<input type='submit' name='" +
        $(submitButton).attr('name') +
        "' value='" +
        $(submitButton).attr('value') +
        "'>",
    )
  })
})

// Handungsanlässe
let caseArray
let solutionArray
let actualCase
let actualCaseImage
let actualCaseVideo
let actualCaseVideoSource
let caseCount
let caseCountInput
let actualCaseInt
let actualSolution
let caseRefresh
let caseRefreshIcon
let rotationCases = 0

let solutionDiv
let mediathekDiv
let legendDiv

let refreshButtonIcon

let dentsDiv
let dentsButton

let modelButton
let modelDiv

let solutionButton
let mediathekButton
let legendButton

let caseSelectors

$(window).ready(function () {
  caseArray = $('.case-thumbnail')
  solutionArray = $('.solution')
  caseCount = $('#case-count')
  caseCountInput = $('#case-count-input')
  actualCaseImage = $('#case-actual-image')
  actualCaseVideo = $('#case-actual-video')
  actualCaseVideoSource = $('#case-actual-video-source')

  caseRefreshIcon = $('.handlungsanlaesse .refresh i')[0]
  $('.handlungsanlaesse .refresh').click(function () {
    rotationCases += 180
    randomCase()
    $(caseRefreshIcon).css('transform', 'rotate(' + rotationCases + 'deg)')
  })
  $('.handlungsanlaesse .refresh').hover(
    function () {
      rotationCases += 45
      $(caseRefreshIcon).css('transform', 'rotate(' + rotationCases + 'deg)')
    },
    function () {
      rotationCases -= 45
      $(caseRefreshIcon).css('transform', 'rotate(' + rotationCases + 'deg)')
    },
  )

  $('.handlungsanlaesse .next').click(function () {
    actualCaseInt++
    updateCase()
  })

  $('.handlungsanlaesse .previous').click(function () {
    actualCaseInt--
    updateCase()
  })

  $('#case-actual-image').click(function () {
    if (!$(this).hasClass('zoom')) {
      $(this).addClass('zoom')
      $(this).css('cursor', 'zoom-out')
      const imageW = $(this).width()
      const imageH = $(this).height()
      const xOffset =
        -((mouseX - $(this).parent().offset().left) / imageW - 0.5) * 100
      const yOffset =
        -((mouseY - $(this).parent().offset().top) / imageH - 0.5) * 100
      const transformImage =
        'translate(' + xOffset + '%, ' + yOffset + '%) ' + 'scale(2)'
      $(this).css('transform', transformImage)
    } else {
      $(this).removeClass('zoom')
      $(this).css('cursor', 'zoom-in')
      $(this).css('transform', 'scale(1)')
    }
  })
  $('#case-actual-image').mousemove(function () {
    if ($(this).hasClass('zoom')) {
      const imageW = $(this).width()
      const imageH = $(this).height()
      const xOffset =
        -((mouseX - $(this).parent().offset().left) / imageW - 0.5) * 100
      const yOffset =
        -((mouseY - $(this).parent().offset().top) / imageH - 0.5) * 100
      const transformImage =
        'translate(' + xOffset + '%, ' + yOffset + '%) ' + 'scale(2)'
      $(this).css('transform', transformImage)
    }
  })

  const links = $('.case-links a')
  links.each(function () {
    $(this).attr('target', 'blank')
  })

  caseCountInput.change(function () {
    actualCaseInt = caseCountInput.val() - 1
    updateCase()
  })

  //Side Case Menu
  solutionDiv = $('#cases-solutions')
  mediathekDiv = $('#cases-thumbnails')
  legendDiv = $('#cases-legend')
  dentsDiv = $('#cases-dents')
  modelDiv = $('#cases-model')

  solutionButton = $('#cases-solutions-button')
  mediathekButton = $('#cases-thumbnails-button')
  legendButton = $('#cases-legend-button')
  dentsButton = $('#cases-dents-button')
  modelButton = $('#cases-model-button')

  solutionButton.click(function () {
    if (!$(solutionButton).hasClass('active'))
      $(solutionButton).addClass('active')
    if ($(solutionDiv).hasClass('hidden')) $(solutionDiv).removeClass('hidden')

    if ($(mediathekButton).hasClass('active'))
      $(mediathekButton).removeClass('active')
    if (!$(mediathekDiv).hasClass('hidden')) $(mediathekDiv).addClass('hidden')
    if ($(legendButton).hasClass('active'))
      $(legendButton).removeClass('active')
    if (!$(legendDiv).hasClass('hidden')) $(legendDiv).addClass('hidden')
    if ($(dentsButton).hasClass('active')) $(dentsButton).removeClass('active')
    if (!$(dentsDiv).hasClass('hidden')) $(dentsDiv).addClass('hidden')
    if ($(modelButton).hasClass('active')) $(modelButton).removeClass('active')
    if (!$(modelDiv).hasClass('hidden')) $(modelDiv).addClass('hidden')
  })

  mediathekButton.click(function () {
    if (!$(mediathekButton).hasClass('active'))
      $(mediathekButton).addClass('active')
    if ($(mediathekDiv).hasClass('hidden'))
      $(mediathekDiv).removeClass('hidden')

    if ($(solutionButton).hasClass('active'))
      $(solutionButton).removeClass('active')
    if (!$(solutionDiv).hasClass('hidden')) $(solutionDiv).addClass('hidden')
    if ($(legendButton).hasClass('active'))
      $(legendButton).removeClass('active')
    if (!$(legendDiv).hasClass('hidden')) $(legendDiv).addClass('hidden')
    if ($(dentsButton).hasClass('active')) $(dentsButton).removeClass('active')
    if (!$(dentsDiv).hasClass('hidden')) $(dentsDiv).addClass('hidden')
    if ($(modelButton).hasClass('active')) $(modelButton).removeClass('active')
    if (!$(modelDiv).hasClass('hidden')) $(modelDiv).addClass('hidden')
  })

  legendButton.click(function () {
    if (!$(legendButton).hasClass('active')) $(legendButton).addClass('active')
    if ($(legendDiv).hasClass('hidden')) $(legendDiv).removeClass('hidden')

    if ($(solutionButton).hasClass('active'))
      $(solutionButton).removeClass('active')
    if (!$(solutionDiv).hasClass('hidden')) $(solutionDiv).addClass('hidden')
    if ($(mediathekButton).hasClass('active'))
      $(mediathekButton).removeClass('active')
    if (!$(mediathekDiv).hasClass('hidden')) $(mediathekDiv).addClass('hidden')
    if ($(dentsButton).hasClass('active')) $(dentsButton).removeClass('active')
    if (!$(dentsDiv).hasClass('hidden')) $(dentsDiv).addClass('hidden')
    if ($(modelButton).hasClass('active')) $(modelButton).removeClass('active')
    if (!$(modelDiv).hasClass('hidden')) $(modelDiv).addClass('hidden')
  })

  dentsButton.click(function () {
    if (!$(dentsButton).hasClass('active')) $(dentsButton).addClass('active')
    if ($(dentsDiv).hasClass('hidden')) $(dentsDiv).removeClass('hidden')

    if ($(legendButton).hasClass('active'))
      $(legendButton).removeClass('active')
    if (!$(legendDiv).hasClass('hidden')) $(legendDiv).addClass('hidden')
    if ($(solutionButton).hasClass('active'))
      $(solutionButton).removeClass('active')
    if (!$(solutionDiv).hasClass('hidden')) $(solutionDiv).addClass('hidden')
    if ($(mediathekButton).hasClass('active'))
      $(mediathekButton).removeClass('active')
    if (!$(mediathekDiv).hasClass('hidden')) $(mediathekDiv).addClass('hidden')
    if ($(modelButton).hasClass('active')) $(modelButton).removeClass('active')
    if (!$(modelDiv).hasClass('hidden')) $(modelDiv).addClass('hidden')
  })

  modelButton.click(function () {
    if (!$(modelButton).hasClass('active')) $(modelButton).addClass('active')
    if ($(modelDiv).hasClass('hidden')) $(modelDiv).removeClass('hidden')

    if ($(legendButton).hasClass('active'))
      $(legendButton).removeClass('active')
    if (!$(legendDiv).hasClass('hidden')) $(legendDiv).addClass('hidden')
    if ($(solutionButton).hasClass('active'))
      $(solutionButton).removeClass('active')
    if (!$(solutionDiv).hasClass('hidden')) $(solutionDiv).addClass('hidden')
    if ($(mediathekButton).hasClass('active'))
      $(mediathekButton).removeClass('active')
    if (!$(mediathekDiv).hasClass('hidden')) $(mediathekDiv).addClass('hidden')
    if ($(dentsButton).hasClass('active')) $(dentsButton).removeClass('active')
    if (!$(dentsDiv).hasClass('hidden')) $(dentsDiv).addClass('hidden')
  })

  caseSelectors = $('button.case-selector')
  for (let i = 0; i < caseSelectors.length; i++) {
    $(caseSelectors[i]).click(function () {
      actualCaseInt = i
      updateCase()
    })
  }

  actualCaseInt = 0
  if (caseArray.length > 0) {
    updateCase()
    $('#case-count-all').text('von ' + caseArray.length)
    $(caseCountInput).attr('max', caseArray.length)
  }
})

//Arrow keys
document.addEventListener('keydown', (event) => {
  if (
    $('.handlungsanlaesse')[0] != null &&
    !$($('.handlungsanlaesse')[0]).hasClass('hidden')
  ) {
    switch (event.key) {
      case 'Escape': // ESC
        $($('.handlungsanlaesse')[0]).addClass('hidden')
        break
      case 'PageUp': // PageUp
        actualCaseInt--
        updateCase()
        break
      case 'PageDown': // PageDown
        actualCaseInt++
        updateCase()
        break
      case 'ArrowLeft': // left
        actualCaseInt--
        updateCase()
        break
      case 'ArrowRight': // right
        actualCaseInt++
        updateCase()
        break
      default:
        return // exit this handler for other keys
    }
    event.preventDefault() // prevent the default action (scroll / move caret)
  }
})

function randomCase() {
  const lastCaseInt = actualCaseInt
  while (actualCaseInt == lastCaseInt) {
    actualCaseInt = Math.floor(Math.random() * caseArray.length)
  }
  updateCase()
}

function updateCase() {
  if (actualCaseInt >= caseArray.length) {
    actualCaseInt = 0
  } else if (actualCaseInt < 0) {
    actualCaseInt = caseArray.length - 1
  }
  caseSelectors.each(function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
    }
  })
  if (!$(caseSelectors[actualCaseInt]).hasClass('active')) {
    $(caseSelectors[actualCaseInt]).addClass('active')
    caseSelectors[actualCaseInt].scrollIntoView()
  }
  //Reset previous case
  if (!$(actualSolution).hasClass('hidden')) {
    $(actualSolution).addClass('hidden')
  }
  //Show new case
  actualCase = caseArray[actualCaseInt]
  actualSolution = solutionArray[actualCaseInt]
  $(actualSolution).removeClass('hidden')

  if (!$(actualCase).attr('video')) {
    if ($(actualCaseImage).hasClass('hidden')) {
      $(actualCaseImage).removeClass('hidden')
      $(actualCaseVideo).addClass('hidden')
    }
    $(actualCaseImage).attr('src', '')
    $(actualCaseImage).attr('src', $(actualCase).attr('large'))
  } else {
    if ($(actualCaseVideo).hasClass('hidden')) {
      $(actualCaseVideo).removeClass('hidden')
      $(actualCaseImage).addClass('hidden')
    }
    $(actualCaseVideoSource).attr('src', '')
    $(actualCaseVideoSource).attr('src', $(actualCase).attr('video'))
    $(actualCaseVideo).attr('poster', $(actualCase).attr('large'))
    $(actualCaseVideo)[0].load()
  }

  $(caseCountInput).val(actualCaseInt + 1)

  // Reset to Mediathek
  if (!$(mediathekButton).hasClass('active'))
    $(mediathekButton).addClass('active')
  if ($(mediathekDiv).hasClass('hidden')) $(mediathekDiv).removeClass('hidden')
  if ($(solutionButton).hasClass('active'))
    $(solutionButton).removeClass('active')
  if (!$(solutionDiv).hasClass('hidden')) $(solutionDiv).addClass('hidden')
  if ($(legendButton).hasClass('active')) $(legendButton).removeClass('active')
  if (!$(legendDiv).hasClass('hidden')) $(legendDiv).addClass('hidden')
  if ($(dentsButton).hasClass('active')) $(dentsButton).removeClass('active')
  if (!$(dentsDiv).hasClass('hidden')) $(dentsDiv).addClass('hidden')
  if ($(modelButton).hasClass('active')) $(modelButton).removeClass('active')
  if (!$(modelDiv).hasClass('hidden')) $(modelDiv).addClass('hidden')
}

// Facts
let randomFacts
let refreshButton
let lastActiveFact
let rotationFacts = 0
$(window).ready(function () {
  randomFacts = $('.random-fact')
  refreshButton = $('#random-fact-refresh')
  refreshButtonIcon = $('#random-fact-refresh i')[0]
  refreshButton.click(function () {
    generateFact()
  })
  refreshButton.hover(
    function () {
      rotationFacts += 45
      $(refreshButtonIcon).css('transform', 'rotate(' + rotationFacts + 'deg)')
    },
    function () {
      rotationFacts -= 45
      $(refreshButtonIcon).css('transform', 'rotate(' + rotationFacts + 'deg)')
    },
  )
  generateFact()
})

function generateFact() {
  rotationFacts += 180
  let activeFact
  if (randomFacts.length > 1) {
    do {
      activeFact = Math.floor(Math.random() * randomFacts.length)
    } while (lastActiveFact == activeFact)
    lastActiveFact = activeFact
    randomFacts.each(function (index) {
      $(this).hide()
      $(this).css('transform', 'translateY(20px)')
      $(this).css('transition', 'transform 0.7s ease-out')
      if (index == activeFact) {
        $(this).fadeIn(700)
        $(this).css('display', 'flex')
        $(this).css('transform', 'translateY(0px)')
      }
    })
  }
  $(refreshButtonIcon).css('transform', 'rotate(' + rotationFacts + 'deg)')
}

// Menu onscroll minimieren
// Link nach oben einblenden

const topVideo = false

let pScroll = 0
$(window).scroll(function (event) {
  const scroll = $(window).scrollTop()

  // Back to Top
  const totop = $('#totop')
  if (scroll > 0) {
    totop.css('opacity', '1')
    totop.css('transform', 'translateY(0px)')
  } else {
    totop.css('opacity', '0')
    totop.css('transform', 'translateY(5px)')
  }

  pScroll = scroll
})
$(window).ready(function () {
  const totop = $('#totop')
  totop.click(function () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  })
})

// Events
$(window).ready(function () {
  const outdated = $('.outdated')
  const outdatedEventsButton = $('button.outdated-events')
  if (outdated.length > 0) {
    outdatedEventsButton.css('display', 'flex')
  }
  outdatedEventsButton.click(function () {
    if (outdated.css('display') == 'none') {
      outdated.css('display', 'flex')
      outdatedEventsButton.text('Vergangene Veranstaltungen ausblenden')
    } else {
      outdated.css('display', 'none')
      outdatedEventsButton.text('Vergangene Veranstaltungen anzeigen')
    }
  })
})

// Home Titlevideo
if ($('#title-video video').length > 0) {
  $('#title-video video')[0].playbackRate = 0.9
  $('#title-video-play-button').click(function (e) {
    if ($('#title-video-play-button').text() === '') {
      $('#title-video-play-button').text('')
      $('#title-video-play-button').attr('aria-label', 'Video fortsetzen')
      $('#title-video video')[0].pause()
    } else {
      $('#title-video-play-button').text('')
      $('#title-video-play-button').attr('aria-label', 'Video pausieren')
      $('#title-video video')[0].play()
    }
  })
}

// Footer intro-videos
let actualIntroVideo = 0
if ($('#intro-video').length > 0) {
  $('#intro-video-start-button').click(function (e) {
    $('#intro-video').addClass('active')
    $('#intro-video-start-button').addClass('hidden')
    $($('#intro-video video')[actualIntroVideo]).parent().removeClass('hidden')
    $($('#intro-video video')[actualIntroVideo]).removeClass('hidden')
    $('#intro-video video')[actualIntroVideo].play()
    $('#intro-video-pause-button').text('')
  })

  $('#intro-video-close-button').click(function (e) {
    $('#intro-video video')[actualIntroVideo].pause()
    $('#intro-video video')[actualIntroVideo].currentTime = 0
    $('#intro-video-pause-button').text('')
    $('#intro-video').removeClass('active')
    $('#intro-video-start-button').removeClass('hidden')
    $($('#intro-video video')[actualIntroVideo]).parent().addClass('hidden')
  })

  $('#intro-video-restart-button').click(function (e) {
    $('#intro-video video')[actualIntroVideo].currentTime = 0
    $('#intro-video-pause-button').text('')
    if ($('#intro-video video')[actualIntroVideo].paused) {
      $('#intro-video video')[actualIntroVideo].play()
    }
  })

  $('#intro-video-pause-button').click(function (e) {
    if ($('#intro-video video')[actualIntroVideo].paused) {
      $('#intro-video video')[actualIntroVideo].play()
      $('#intro-video-pause-button').text('')
    } else {
      $('#intro-video video')[actualIntroVideo].pause()
      $('#intro-video-pause-button').text('')
    }
  })

  if ($('#intro-video-previous-button')) {
    $('#intro-video-previous-button').click(function (e) {
      // Stop
      $($('#intro-video video')[actualIntroVideo]).addClass('hidden')
      $('#intro-video video')[actualIntroVideo].pause()
      $('#intro-video video')[actualIntroVideo].currentTime = 0
      // Next
      actualIntroVideo--
      if (actualIntroVideo < 0) {
        actualIntroVideo = $('#intro-video video').length - 1
      }
      $($('#intro-video video')[actualIntroVideo]).removeClass('hidden')
      $('#intro-video video')[actualIntroVideo].play()
      $('#intro-video-pause-button').text('')
    })
  }
  if ($('#intro-video-next-button')) {
    $('#intro-video-next-button').click(function (e) {
      // Stop
      $($('#intro-video video')[actualIntroVideo]).addClass('hidden')
      $('#intro-video video')[actualIntroVideo].pause()
      $('#intro-video video')[actualIntroVideo].currentTime = 0
      // Next
      actualIntroVideo++
      if (actualIntroVideo >= $('#intro-video video').length) {
        actualIntroVideo = 0
      }
      $($('#intro-video video')[actualIntroVideo]).removeClass('hidden')
      $('#intro-video video')[actualIntroVideo].play()
      $('#intro-video-pause-button').text('')
    })
  }

  document.querySelectorAll('#intro-video video').forEach((v) => {
    v.addEventListener('ended', (event) => {
      actualIntroVideo++
      if (actualIntroVideo >= $('#intro-video video').length) {
        actualIntroVideo = 0
        $('#intro-video-pause-button').text('')
      } else {
        $(v).addClass('hidden')
        $($('#intro-video video')[actualIntroVideo]).removeClass('hidden')
        $('#intro-video video')[actualIntroVideo].play()
      }
    })
    v.addEventListener('timeupdate', (event) => {
      const circle = document.querySelector('#intro-video-progressring')
      const radius = circle.r.baseVal.value
      const circumference = radius * 2 * Math.PI
      circle.style.strokeDasharray = `${circumference} ${circumference}`
      circle.style.strokeDashoffset = `${circumference}`
      const progress = v.currentTime / v.duration

      const offset = circumference - progress * circumference
      circle.style.strokeDashoffset = offset
    })
  })
}