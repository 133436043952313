import { createApp } from 'vue'
import AccountRegistration from './AccountRegistration.vue'

const AccountRegistrationElement = document.querySelector(
  '.AccountRegistration',
)

if (AccountRegistrationElement) {
  createApp(AccountRegistration).mount(AccountRegistrationElement)
}
