import $ from 'jquery'

const navigationBackgroundElement = $('#menu-bg')
const navigationMobileButtonOpen = $('#menu-mobile-open')
const navigationMobileButtonClose = $('#menu-mobile-close')
const navigationMobile = $('#mobile-menu')

updateNavHeight()
$(window).on('resize', function () {
  updateNavHeight()
})

function updateNavHeight() {
  if ($(window).width() < 750) {
    const navHeight = $('#mobile-menu').outerHeight()
    $('#content').css('padding-top', navHeight)
  } else {
    $('#content').css('padding-top', 0)
  }
}

let prevScrollpos = 0

$(window).scroll(function () {
  const scroll = $(window).scrollTop()

  // menu resing with scrolling
  if (scroll <= prevScrollpos || scroll < 100) {
    navigationMobile.css('top', '0px')
  } else {
    navigationMobile.css('top', -navigationMobile.outerHeight())
  }

  prevScrollpos = scroll
})

navigationBackgroundElement.on('click', closeMenu)
navigationMobileButtonOpen.on('click', openMenu)
navigationMobileButtonClose.on('click', closeMenu)

$(document).ready(function () {
  if (window.innerWidth < 750) {
    closeMenu()
  } else {
    openMenu()
  }
})

$(window).on('resize', function () {
  if (window.innerWidth < 750) {
    closeMenu()
  } else {
    openMenu()
  }
})

//Open & Close Menu
function openMenu() {
  const w = $('#menu').outerWidth()
  $('#menu').css('visibility', 'visible')
  $('#menu').css('left', -w)
  $('#menu').css('left', 0)
  if ($(window).width() < 750) {
    $('#menu-bg').fadeIn(300)
    $('#menu-bg').css('pointer-events', 'auto')
  }
}

function closeMenu() {
  const w = $('#menu').outerWidth()
  $('#menu').css('left', -w)
  $('#menu-bg').fadeOut(300)
  $('#menu-bg').css('pointer-events', 'none')
}
